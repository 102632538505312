import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import './Article.css';

const baseURL = "https://warehouse-picker-proxy.qustom.workers.dev/proxy";

const Article = ({ article, pickedArticles, setPickedArticles ,conneoId, batchId }) => {
  const [locationQuantities, setLocationQuantities] = useState({});
  const [error, setError] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isEditCollapsed, setIsEditCollapsed] = useState(false);
  const [totalPickedQuantity, setTotalPickedQuantity] = useState(
    pickedArticles[article.id]?.mainQuantity || 0
  );
  const [andersQuantity, setAndersQuantity] = useState('');
  const [editQuantity, setEditQuantity] = useState('');
  const [editAltQuantity, setEditAltQuantity] = useState('');
  const [loading, setLoading] = useState(false);
  const [collapsedLocation, setCollapsedLocation] = useState({});
  const [collapsedLocationEdit, setCollapsedLocationEdit] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({}); // Track disabled state of buttons
  const [availableLocations, setAvailableLocations] = useState(
    article.locations.reduce((acc, location) => {
      acc[location.locationId] = location.available;
      return acc;
    }, {})

  ); // Track available stock per location
  const [buttonCaption, setButtonCaption] = useState(
    article.available < article.qty ? article.available : article.qty
  );
  const [andersUsed, setAndersUsed] = useState({}); // Track whether Anders has been used for a location

  // Function to calculate the remaining quantity to be picked
  const calculateRemainingQuantity = () => {
    return Math.max(0, article.qty - totalPickedQuantity);
  };

  const handlePickDefaultQuantity = () => {
    if (article.available === 0) {
      setError('There is not enough stock available.');
      return;
    }
    const remainingQuantity = calculateRemainingQuantity();

    const mainQuantity = Math.min(remainingQuantity , buttonCaption);
    const totalAlternativeQuantity = Object.values(locationQuantities).reduce(
      (acc, qty) => acc + qty,
      0
    );
    const totalQuantity = mainQuantity + totalAlternativeQuantity;

    if (totalQuantity > article.qty) {
      setError(`The total picked quantity (${totalQuantity}) exceeds the ordered quantity (${article.qty}).`);
      return;
    }

    setButtonCaption(0);

    const updatedPickedArticles = {
      ...pickedArticles,
      [article.id]: {
        mainQuantity: mainQuantity,
        locations: locationQuantities,
      },
    };

    setPickedArticles(updatedPickedArticles);
    setTotalPickedQuantity(totalQuantity);
    setError('');
  };

  const handleAddAndersQuantity = () => {
    if (article.available === 0) {
      setError('There is not enough stock available.');
      return;
    }

    const mainQuantity = Number(andersQuantity);
    const totalAlternativeQuantity = Object.values(locationQuantities).reduce((acc, qty) => acc + qty, 0);
    const currentPickedQuantity = pickedArticles[article.id]?.mainQuantity || 0;
    const totalQuantity = mainQuantity + totalAlternativeQuantity + currentPickedQuantity;

    const maxExpectedQty = article.available > article.qty ? article.qty : article.available;

    if (totalAlternativeQuantity > totalQuantity) {
      setError('Exceeded the maximum available quantity!');
      return;
    }
    if (isNaN(mainQuantity) || mainQuantity <= 0 || totalQuantity > article.qty || mainQuantity > maxExpectedQty) {
      setError(`Please enter a valid quantity greater than 0 and ensure the total does not exceed the ordered quantity (${article.qty}).`);
      return;
    }

    const updatedButtonCaption = Math.max(0, buttonCaption - mainQuantity); // Ensure it doesn't go negative

    if (updatedButtonCaption < 0) {
      setError('The quantity entered exceeds the available stock.');
      return;
    }

    setButtonCaption(updatedButtonCaption);
    const updatedTotalPickedQuantity = totalPickedQuantity + mainQuantity;

    const updatedPickedArticles = {
      ...pickedArticles,
      [article.id]: {
        mainQuantity: currentPickedQuantity + mainQuantity,
        locations: locationQuantities,
      },
    };

    setPickedArticles(updatedPickedArticles);
    setTotalPickedQuantity(updatedTotalPickedQuantity);
    setAndersQuantity('');
    setError('');
  };

  const handleLocationQuantityChange = (locationId, value, availableForLocation) => {
    const inputQuantity = Math.max(0, Number(value)); // Ensure no negative values
    // Check if the input quantity exceeds the available quantity
    if (inputQuantity > availableForLocation) {
      setError(`You cannot pick more than the available quantity for this location.`);
      return;
    }

    setLocationQuantities({
      ...locationQuantities,
      [locationId]: inputQuantity,
    });

    // Mark "Anders" as used if the user enters a value
    setAndersUsed((prev) => ({
      ...prev,
      [locationId]: true,
    }));
  };

  const handlePickDefaultQuantityLocation = (location) => {
    if (availableLocations[location.locationId] === 0) {
      setError('There is not enough stock available.');
      return;
    }
  
    const remainingQuantity = calculateRemainingQuantity();
    const pickedQuantity = Math.min(availableLocations[location.locationId], remainingQuantity);
  
    const totalAlternativeQuantity = pickedQuantity + Object.values(locationQuantities).reduce((acc, qty) => acc + qty, 0);
  
    if (totalAlternativeQuantity > article.qty) {
      setError(`The total picked quantity exceeds the ordered quantity (${article.qty}).`);
      return;
    }
  
    // Update picked quantities for the location
    setLocationQuantities((prev) => ({
      ...prev,
      [location.locationId]: pickedQuantity,
    }));
  
    setTotalPickedQuantity(totalPickedQuantity + pickedQuantity);
  
    setPickedArticles((prevPickedArticles) => ({
      ...prevPickedArticles,
      [article.id]: {
        ...prevPickedArticles[article.id],
        locations: {
          ...prevPickedArticles[article.id]?.locations,
          [location.locationId]: pickedQuantity,
        },
      },
    }));
  
    // Update available stock for the location
    setAvailableLocations((prev) => ({
      ...prev,
      [location.locationId]: prev[location.locationId] - pickedQuantity,
    }));
  
    // Disable the green button after clicking
    setDisabledButtons((prev) => ({
      ...prev,
      [location.locationId]: true, // Mark this location button as disabled
    }));
  
    // **Update the buttonCaption based on the remaining quantity to pick**
    const updatedButtonCaption = Math.max(0 , article.qty-totalAlternativeQuantity);
    setButtonCaption(updatedButtonCaption); // Ensure it reflects the correct remaining quantity
  
    setError('');
  };
  
  const handleAddAndersQuantityLocation = (location) => {
    if (availableLocations[location.locationId] === 0) {
      setError('There is not enough stock available.');
      return;
    }
  
    const pickedQuantity = Math.min(Number(locationQuantities[location.locationId]), availableLocations[location.locationId]);
    const remainingQuantity = calculateRemainingQuantity();
  
    // Validate the picked quantity against the available stock and remaining quantity
    if (isNaN(pickedQuantity) || pickedQuantity <= 0 || pickedQuantity > Math.min(remainingQuantity, availableLocations[location.locationId])) {
      setError(`Please enter a valid quantity and ensure it does not exceed the available quantity for this location.`);
      return;
    }
  
    setLocationQuantities((prev) => ({
      ...prev,
      [location.locationId]: pickedQuantity,
    }));
  
    const updatedTotalPickedQuantity = totalPickedQuantity + pickedQuantity;
  
    setPickedArticles((prevPickedArticles) => ({
      ...prevPickedArticles,
      [article.id]: {
        ...prevPickedArticles[article.id],
        locations: {
          ...prevPickedArticles[article.id]?.locations,
          [location.locationId]: (prevPickedArticles[article.id]?.locations[location.locationId] || 0) + pickedQuantity,
        },
      },
    }));
  
    // Update available stock for the location
    setAvailableLocations((prev) => ({
      ...prev,
      [location.locationId]: prev[location.locationId] - pickedQuantity,
    }));
  
    // Disable the green button if "Anders" was used
    setDisabledButtons((prev) => ({
      ...prev,
      [location.locationId]: true, // Disable the green button
    }));
  
    // Mark "Anders" as used
    setAndersUsed((prev) => ({
      ...prev,
      [location.locationId]: true,
    }));
  
    setTotalPickedQuantity(updatedTotalPickedQuantity);
  
    // **Update the buttonCaption based on the remaining quantity to pick**
    const updatedButtonCaption = Math.max(0 , article.qty-updatedTotalPickedQuantity);
    setButtonCaption(updatedButtonCaption);
    setError('');
  };
  
  const handleCollapsibleClick = () => {
    if(isEditCollapsed) setIsEditCollapsed(false);
    setIsCollapsed(!isCollapsed);
  };

  const handleEditCollapsibleClick = () => {
    setIsEditCollapsed(!isEditCollapsed);
    if(isCollapsed) setIsCollapsed(false);
    setCollapsedLocationEdit((prev) => {
      const updatedCollapsedState = {};
      Object.keys(prev).forEach((key) => {
        updatedCollapsedState[key] = false;
      });
        return updatedCollapsedState;
    });
  };

  const handleEditCollapsibleAltClick = (locationId) => {
    collapsedLocation[locationId] = false;
    setIsEditCollapsed(false);
    // setCollapsedLocationEdit((prev) => ({
    //   ...prev,
    //   [locationId]: !prev[locationId],
    // }));
    setCollapsedLocationEdit((prev) => {
      const updatedCollapsedState = {};
        // Loop through existing state and set all to false except the clicked location
      Object.keys(prev).forEach((key) => {
        updatedCollapsedState[key] = key === locationId ? !prev[key] : false;
      });

      // Ensure that the clicked location always exists in the updated state
      updatedCollapsedState[locationId] = !prev[locationId];
        return updatedCollapsedState;
    });
  };

  const handleEditMainQuantity = async () => {
      setLoading(true); // Start loader
      try {
        const payload = {
          articleId: article.paperid,
          code: '',
          articleType: 100,
          quantity: editQuantity,
          locationId: article.locationId,
        };
        const response = await fetch(`${baseURL}/${conneoId}/${batchId}/correctstock`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (!response.ok || data.meta.status !== 'success') throw new Error(data.meta.error);
        else  article.available = data.data.stock;

      } catch (error) {
        alert(error.message);
        console.error(error.message);
      } finally {
        setLoading(false); // Stop loader
      }

    setEditQuantity('');
    setIsEditCollapsed(false)
  };

  const handleEditAltQuantity = async (location) => {
      setLoading(true); // Start loader
      try {
        const payload = {
          articleId: article.paperid,
          code: '',
          articleType: 100,
          quantity: editAltQuantity ,
          locationId: location.locationId,
        };

        
        const response = await fetch(`${baseURL}/${conneoId}/${batchId}/correctstock`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (!response.ok || data.meta.status !== 'success') throw new Error(data.meta.error);
        else location.available = data.data.stock;

      } catch (error) {
        alert(error.message);
        console.error(error.message);
      } finally {
        setLoading(false); // Stop loader
      }
    setEditAltQuantity(null);
    handleEditCollapsibleAltClick(location.locationId);
  };

  const toggleLocationCollapsible = (locationId) => {
    setCollapsedLocation((prev) => ({
      ...prev,
      [locationId]: !prev[locationId],
    }));
    collapsedLocationEdit[locationId] = false;
  };

  const displayDescription = (article) => {
    const normalizedArticleCode = article.articleCode.replace(/[-_]/g, '');
    const normalizedDescription = article.description.replace(/[-_]/g, '');

    if (article.articleCode.includes(article.description))
      return `${article.orderNumber} - ${article.articleCode}`;

    const baseArticleCode = normalizedArticleCode.replace(/\d*$/, ''); // Remove any trailing digits
    return normalizedDescription.startsWith(baseArticleCode)
      ? article.orderNumber + ' - ' + article.description
      : `${article.orderNumber} - ${article.articleCode} - ${article.description}`;
  };

  return (
    <div className="article-card">
        {loading && (
        <div className="fullscreen-loader">
          <div className="spinner"></div>
        </div>
      )}
      <h3>{displayDescription(article)}</h3>
      <div className="article-details">
        <p>
          <strong>{article.location}</strong> ({article.warehouse}) [{article.available}]
        </p>
        <p><strong>Besteld:</strong> {article.qty}</p>
        <p><strong>Gepakt:</strong> {totalPickedQuantity}</p>
        <div className="button-group">
          <button onClick={handlePickDefaultQuantity} className="btn-default"  disabled={buttonCaption === 0}>
            {buttonCaption}
          </button>
          <button
            onClick={handleCollapsibleClick}
            className="btn-anders"
            disabled={buttonCaption === 0} // Disable "Anders" when buttonCaption is 0
          >
            Anders
          </button>
          {/* New Edit Button */}
          <button
            onClick={handleEditCollapsibleClick}
            className="btn-edit"
          >
            <span className="edit-icon"> ✎</span>
          </button>
        </div>
        {isCollapsed && (
          <div className="anders-input-group">
            <input
              type="number"
              value={andersQuantity}
              onChange={(e) => setAndersQuantity(e.target.value)}
              placeholder="Aantal gepakt"
              className="input-quantity anders-input"
            />
            <button onClick={handleAddAndersQuantity} className="btn-check-mark">
              ✔
            </button>
          </div>
        )}

      {isEditCollapsed && (
          <div className="anders-input-group">
            <input
              type="number"
              value={editQuantity}
              onChange={(e) => setEditQuantity(e.target.value)}
              placeholder="Aantal wijzigen"
              className="input-quantity anders-input"
              disabled={loading} // Disable input while loading
            />
            <button onClick={handleEditMainQuantity} className="btn-check-mark-edit" disabled={loading}>
            {loading ? 'Processing...' : '✔'}
            </button>
          </div>
        )}
      </div>

      {article.locations && article.locations.length > 0 && (
        <Collapsible
          trigger={<div className={`collapsible-trigger ${isCollapsed ? '' : 'active'}`}>Andere locaties</div>}
          open={isCollapsed}
          className="collapsible"
        >
          {article.locations.map((location, index) => {
            const remainingQuantity = calculateRemainingQuantity();
            const availableForLocation = Math.min(remainingQuantity, availableLocations[location.locationId]);
            const pickedForLocation = locationQuantities[location.locationId] || 0;
            const isLocationFullyPicked = pickedForLocation >= availableForLocation;

            return (
              <div key={index} className="alternative-location">
                <p>
                  <strong>{location.location}</strong> ({location.warehouse}) [{location.available}]
                </p>
                <div className="button-group">
                  <button
                    onClick={() => handlePickDefaultQuantityLocation(location)}
                    className="btn-default" 
                    disabled={disabledButtons[location.locationId] || andersUsed[location.locationId]} // Disable if Anders used or green button clicked
                  >
                    {availableForLocation}
                  </button>
                  <button
                    onClick={() => toggleLocationCollapsible(location.locationId)}
                    className="btn-anders"
                    disabled={availableForLocation === 0 || isLocationFullyPicked || disabledButtons[location.locationId]} // Disable after default quantity is picked
                  >
                    Anders
                  </button>
                  <button
                  onClick={() => handleEditCollapsibleAltClick(location.locationId)}
                  className="btn-edit"
                 >
                  <span className="edit-icon"> ✎</span>
                </button>
                </div>
                {collapsedLocation[location.locationId] && (
                  <div className="anders-input-group">
                    <input
                      type="number"
                      value={locationQuantities[location.locationId] || ''}
                      placeholder="Aantal gepakt"
                      className="input-quantity anders-input"
                      onChange={(e) =>
                        handleLocationQuantityChange(location.locationId, e.target.value, availableForLocation)
                      }
                    />
                    <button
                      onClick={() => handleAddAndersQuantityLocation(location)}
                      className="btn-check-mark"
                      disabled={Number(locationQuantities[location.locationId]) > availableForLocation} // Disable if input exceeds available
                    >
                      ✔
                    </button>
                  </div>
                )}
              {collapsedLocationEdit[location.locationId] && (
                   <div className="anders-input-group">
                   <input
                     type="number"
                     value={editAltQuantity}
                     onChange={(e) => setEditAltQuantity(e.target.value)}
                     placeholder="Aantal wijzigen"
                     className="input-quantity anders-input"
                     disabled={loading}
                   />
                   <button onClick={() => handleEditAltQuantity(location)} className="btn-check-mark-edit" disabled={loading}>
                   {loading ? 'Processing...' : '✔'}
                   </button>
                 </div>
                )}
              </div>
            );
          })}
        </Collapsible>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Article;